import classnames from 'classnames';
import { forwardRef } from 'react';

import styles from './Button.module.css';

export default forwardRef(function Button(
	{ children, danger, disabled, primary, secondary, loading, ...props },
	ref
) {
	const classes = classnames(styles.baseButton, props.className, {
		[styles.primary]: primary,
		[styles.secondary]: secondary,
		[styles.loading]: loading,
		[styles.disabled]: disabled,
		[styles.danger]: danger,
	});

	return (
		<button {...props} ref={ref} className={classes}>
			{children}
		</button>
	);
});
